(function ($) {
    $('.slide-toggle').on('click', function () {
        const slide = $(this).parent();
        const wrap = slide.find('.slide-content-wrap');
        const content = slide.find('.slide-content');
        slide.toggleClass('open');
        slide.find('.toggle i').toggleClass('.bi-chevron-compact-down bi-chevron-compact-up');
        if (slide.hasClass('open')) {
            wrap.animate({height: content.outerHeight()});
        } else {
            wrap.animate({height: 0});
        }
    });
    $('.offices-list > .office-item:first').find('.slide-toggle').trigger('click');
})(jQuery);
