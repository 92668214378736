(function () {
    const wrap = $('.page-achievements-content');
    const content = wrap.find('.content');
    const rows = content.find('.row');
    const loadBtn = wrap.find('#load-more');
    const noMoreBtn = wrap.find('.no-more');

    let page = 1;
    const perPage = 2;
    const load = function (start, end) {
        rows.each(function (i, r) {
            if (i >= start && i < end) {
                $(this).removeClass('d-none');
            }
        });
        if (start) {
            $('html, body').animate({scrollTop: rows.eq(start).offset().top});
        }
    }

    loadBtn.on('click', function (e) {
        e.preventDefault();
        const start = (page - 1) * perPage;
        const end = start + perPage;
        load(start, end);
        if (end > rows.length) {
            loadBtn.toggleClass('d-none');
            noMoreBtn.toggleClass('d-none');
        }
        page++;
        return false;
    }).trigger('click');
})();