(function (sp) {
    const swiper1 = new sp('.slideshow-home1 .swiper', {
        loop: true,
        autoplay: {
            delay: 5000
        },
        pagination: {
            el: '.slideshow-home1 .swiper-pagination',
            clickable: true
        },
    });

    const swiper2 = new sp('.slideshow-achievements .swiper', {
        loop: true,
        slidesPerView: 2,
        spaceBetween: 10,
        autoplay: {
            delay: 3000
        },
        breakpoints: {
            640: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 4,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 5,
                spaceBetween: 100,
            },
        },
    });

    const swiper3 = new sp('.slideshow-scope-services .swiper', {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        autoplay: {
            delay: 4000
        },
        navigation: {
            nextEl: '.block-scope-services .swiper-button-next',
            prevEl: '.block-scope-services .swiper-button-prev',
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 15,
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            1200: {
                slidesPerView: 'auto',
                spaceBetween: 30,
            },
        },
    });

    const swiper4 = new sp('.slideshow-client-testimonials .swiper', {
        // If we need pagination
        slidesPerView: 1,
        spaceBetween: 2,
        autoplay: {
            delay: 4000
        },
        pagination: {
            el: '.slideshow-client-testimonials .swiper-pagination',
            type: 'fraction',
            renderFraction: function (currentClass, totalClass) {
                return '<span class="' + currentClass + '"></span>' +
                    ' / ' +
                    '<span class="' + totalClass + '"></span>';
            }
        },
        navigation: {
            nextEl: '.slideshow-client-testimonials .swiper-button-next',
            prevEl: '.slideshow-client-testimonials .swiper-button-prev',
        },
    });

    const swiper5 = new sp('.slideshow-newsletter .swiper', {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        autoplay: {
            delay: 3000
        },
        navigation: {
            nextEl: '.slideshow-newsletter .swiper-button-next',
            prevEl: '.slideshow-newsletter .swiper-button-prev',
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 15,
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
        },
    });

    const swiper6 = new sp('.slideshow-related .swiper', {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        autoplay: {
            delay: 3000
        },
        navigation: {
            nextEl: '.slideshow-related .swiper-button-next',
            prevEl: '.slideshow-related .swiper-button-prev',
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 80,
            },
        },
    });

    $('.achievement-slideshow').each(function (i, n) {
        new sp('.achievement-slideshow' + (i + 1) + ' .swiper', {
            loop: true,
            slidesPerView: 1,
            spaceBetween: 0,
            autoplay: {
                delay: Math.floor(Math.random() * (7000 - 1000) + 1000)
            },
            pagination: {
                el: '.achievement-slideshow' + (i + 1) + ' .swiper-pagination',
                clickable: true
            },
        });
    });
})(Swiper);