(function ($) {
    const form = $('.contact-form');
    const feedback = function (message) {
        if (message) {
            $('#contact-form-modal').modal('hide');
            $('#feedback-message').text(message);
            $('#feedback-modal').modal('show');
        }
    }
    form.on('submit', function (e) {
        const that = this;
        e.preventDefault();
        const btnText = $(this).find('button[type="submit"]').text();
        $(this).find('button[type="submit"]').prop('disabled', true).text('Sending...');
        $.post($(this).attr('action'), $(this).serialize(), function (result) {
            feedback(result.message);
            $(that).find('button[type="submit"]').prop('disabled', false).text(btnText);
        }, 'json');

        // const message = 'Your information has been submitted, we will communicate with you as soon as possible, please wait patiently, thank you.';
        // feedback(message);

        return false;
    });
    const captcha = $('form img.captcha');
    const src = captcha.attr('src');
    $('form .captcha').on('click', function () {
        captcha.attr('src', src + '?s=' + Math.random());
    });
})(jQuery);